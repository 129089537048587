// eslint-disable-next-line no-sparse-arrays
const sideeffects = [
    "decreased inhibition", 
    "bizarre behavior", 
    "agitation",
    "depersonalization",
    "unpleasant mouth taste", 
    "somnolence", 
    "respiratory infections", 
    "dizziness",
    "dry mouth",
    "anxiety", 
    "hallucinations", 
    "viral infections",
    "allergic reactions", 
    "cellulitis",
    "fever",
    "halitosis",
    "heat stroke",
    "hernia", 
    "malaise", 
    "neck rigidity",
    "photosensitivity",
    "agitation",
    "hypesthesia", 
    "vertigo",
    "body tremors",
    "ataxia", 
    "hostility", 
    "insomnia",  
    "memory loss",
    "paresthesia", 
    "decreased reflexes", 
    "abnormal gait",
    "neuritis",
    "chest pain",
    "malaise",
    "internal bleeding",
    "cellulitis",
    "headaches", 
    "abdominal pain", 
    "nausea", 
    "body rash",
    "acne", 
    "cystitis", 
    "dry eyes", 
    "vesiculobullous rash", 
    "ear pain", 
    "alopecia", 
    "contact dermatitis", 
    "metrorrhagia",
    "urinary incontinence",  
    "tinnitus", 
    "photophobia",
     "eczema",
    "excessive sweating",
    "kidney pain", 
    "mastitis",
    "conjunctivitis",
    "urticaria",
    "asthma",
    "bronchitis", 
    "hiccups",
    "laryngitis",
    "hypertonia", 
    "joint disorders",
    "neck rigidity",
    "weight loss, weight gain", 
    "gout",
    "dehydration",
    "arthritis", 
    "leg cramps", 
    "decreased reflexes"
]

export default sideeffects;