import React, { useState, useEffect } from 'react';
import {prefixdata, middledata, suffixdata} from './dataset2';
import './App.css';
import Menu from './Menu';

function Comparison() {

  // Random Number Generators
    const aBigRandomNumber = Math.floor(Math.random() * 5160) + 1
    const randomNumberPrefix = Math.floor(Math.random() * 40) + 1
    const randomNumberMiddle = Math.floor(Math.random() * 32) + 1
    const randomNumberSuffix = Math.floor(Math.random() * 57) + 1
    const fiftyPercentChance = Math.floor(Math.random() * 2)

  // Use State
    const [realName, setRealName] = useState();
    const [fakeName, setFakeName] = useState();
    const [textColorCorrect, setTextColorCorrect] = useState();
    const [textColorWrong, setTextColorWrong] = useState();
    const [order, setOrder] = useState(); 
    const [optionalText, setOptionalText] = useState(); 
    const [counterObject, setCounterObject] = useState({
      count: 0,
      color: 'black'
  });

  // API Call
    const makeAPICall = () => (
      fetch("https://rxnav.nlm.nih.gov/REST/allconcepts.json?tty=BN")
      .then((response) => response.json())
      .then((results) => setRealName(results.minConceptGroup.minConcept[aBigRandomNumber].name)) )

  // Use Effect
    useEffect(() => {
      makeAPICall();
      handleComparison();
      randomOrder();
      }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Handle Clicks
  const handleComparison = () => {
    setFakeName(prefixdata[randomNumberPrefix] + middledata[randomNumberMiddle] + suffixdata[randomNumberSuffix]);
    makeAPICall(realName);
    setTextColorCorrect('black');
    setTextColorWrong('black');
    setOrder(fiftyPercentChance);
    setOptionalText(' ');
    setCounterObject({ 
      ...counterObject,
      color: "black", 
      count: counterObject.count,    
  });
  }

  const handleCorrectGuess = () => {
    setTextColorCorrect('#1ABC00');
    handleIncrement(); 
    setOptionalText(`✅ Correct! ${realName} is a real drug currently available on the market.`);
  }

  const handleWrongGuess = () => {
    setTextColorWrong('#E00700');
    handleDecrement(); 
    setOptionalText(`❗ Incorrect! ${fakeName} is a name generated by Medpramin.`);
  }

// Counter
  const handleIncrement = () => {
    setCounterObject({ 
        ...counterObject,
        color: "#1ABC00", 
        count: counterObject.count + 1,    
    }); };

  const handleDecrement = () => {
      setCounterObject({ 
          ...counterObject,
          color: "#E00700", 
          count: counterObject.count - 1,    
      }); };

  // Random Order Function
    const randomOrder = () => {

        if (order === 0) { 
            return (
        <div className="game">
        <p className="drug-names" style={{color:textColorCorrect}} onClick={() => {handleCorrectGuess()}}>{realName ? realName : 'Loading...'}</p> 
        <p className="versus">vs.</p>
        <p className="drug-names" style={{color:textColorWrong}} onClick={() => {handleWrongGuess()}}>{fakeName ? fakeName : 'Venpradone'}</p>
        </div>)    
        } 
        
        else {
            return (
        <div className="game">
        <p className="drug-names" style={{color:textColorWrong}} onClick={() => {handleWrongGuess()}}>{fakeName ? fakeName : 'Loading...'}</p>
        <p className="versus">vs.</p>
        <p className="drug-names" style={{color:textColorCorrect}} onClick={() => {handleCorrectGuess()}}>{realName ? realName : 'Loading...'}</p> 
        </div>
            )

        }
    }


  // Return
  return (
    <div className="comparison yellow-image">
          <br/><br/>
      <h3 className="comparison-title">Drug Name Comparison</h3>
          <br/>
      <p style={{ color: counterObject.color }} className="counter"><span className="trophy">🏆</span>{counterObject.count}</p>
      <p className="comparison-text">Let's see how <em>Medpramin's</em> randomly-generated names compare to current prescription drug names on the market.<br/><br/>Below, one name is a <span className="real-product">real product</span> and the other is a <span className="fake-product">name generated by <em>Medpramin</em></span>. Try to identify the <span className="real-product">real product</span>.</p>
          <br/>
        <div className="comparison-container"><button className="comparison-button" onClick={() => {handleComparison()}}>Generate a New Comparison</button></div>
          <br/>
        {randomOrder()}
            <br/><br/>
        <p className="optional-text">{optionalText}</p>

        <Menu />

    </div>
  );
}

export default Comparison;
